
.main-wrapper {
       height: 82%;
       padding: 0 30px ;    
        .title{
           text-align: center;
           padding-bottom: 30px;
           .titleData{
               font-weight: bold; 
           }
       }
     .el-scrollbar {
         height: 100%;
       ::v-deep .el-scrollbar__wrap {
           height: 100%;
           overflow-x: hidden;    
       }
     }
     .footer-button{
         text-align: center;
         padding-top: 15px;
     } 
}
